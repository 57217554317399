@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0 40px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.App {
}

h1 {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 40px 0;
}

.desc {
  font-size: 20px;
  text-align: center;
  max-width: 600px;
  margin: auto;
}

.btn-container {
  background-color: #26b15e;
  padding: 15px;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-top: 50px;
}

.save-life-btn {
  color: white;
  padding: 15px;
  font-weight: bold;
  font-size: 25px;
  text-decoration: none;
}

.button-desc {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px;
  text-align: right;
  font-size: 14px;
}

a {
  text-decoration: underline;
  color: black;
}
